<template>
  <div class="container">
    <a-button
      @click="$router.back()"
      style="border-radius: 20px; background-image: linear-gradient(to right, #1D2671 , #830051); color: white;margin-bottom: 10px;"
      ><LeftOutlined /> Back</a-button
    >
    <div class="row">
      <div class="col-12 col-xl-9">
        <div class="card" style="height: 800px">
          <iframe
            :src="pdfRoute"
            frameBorder="0"
            scrolling="auto"
            height="100%"
            width="100%"
          ></iframe>
        </div>
      </div>
      <div class="col-12 col-xl-3">
        <a-select
          ref="select"
          v-model:value="selectedDoctorId"
          style="width: 100%"
          show-search
          :filter-option="false"
          @search="onSearchDocter"
        >
          <a-select-option value="" disabled selected>
            Pilih atau cari dokter
          </a-select-option>
          <a-select-option :value="doctor.id" v-for="(doctor, idx) in filteredDoctors" :key="idx">{{
            doctor.fullname
          }}</a-select-option>
        </a-select>
        <a-button
          type="primary"
          html-type="submit"
          :class="$style['custom-button-yes']"
          :loading="loading"
          @click="showModal"
          v-if="!isSigned"
          :disabled="!selectedDoctorId"
        >
          <strong>Tanda tangani Dokumen</strong>
        </a-button>
        <a-button
          type="primary"
          html-type="submit"
          :class="$style['custom-button-yes']"
          :loading="loading"
          @click="submitDocument"
          v-else
        >
          <strong>Konfirmasi Dokumen</strong>
        </a-button>
      </div>
    </div>
    <a-modal
      v-model:visible="visible"
      title="Tanda Tangan Dokumen"
      @ok="handleOk"
      centered
      :footer="null"
    >
      <div id="signature" class="container">
        <VueSignaturePad ref="signaturePad" :options="options" width="250px" height="250px" />
      </div>
      <div class="buttons">
        <a-button @click="undo">Undo</a-button>
        <a-button @click="save" class="ml-2">Save</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { VueSignaturePad } from 'vue-signature-pad'
import { useRoute, useRouter } from 'vue-router'
import { PDFDocument } from 'pdf-lib' // Import pdf-lib
import {
  ExclamationCircleOutlined,
  CheckCircleTwoTone,
  UploadOutlined,
  LeftOutlined,
} from '@ant-design/icons-vue'
import {
  uploadConsent,
  getListDoctor,
  getListProgramTypeById,
  createProgram,
  getPatientProgram,
} from '../../../services/axios/pulih'
import { notification } from 'ant-design-vue'
export default defineComponent({
  components: {
    VueSignaturePad,
    LeftOutlined,
  },
  setup() {
    const visible = ref(false)
    const signaturePad = ref(null)
    const isSigned = ref(false)
    const store = useStore()
    const filteredDoctors = ref()
    const doctors = ref()
    const selectedDoctorId = ref()
    const route = useRoute()
    const router = useRouter() // Initialize the PDF source
    const pdfRoute = ref('')
    const dataPatient = ref({})
    onBeforeMount(async () => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const response = await getPatientProgram(userData.user.code)
      dataPatient.value = response
      // console.log(dataPatient.value)
      const res = await getListDoctor()
      const programType = await getListProgramTypeById(store.state.programType)
      doctors.value = res
      filteredDoctors.value = res
      const program = store.state.program
      if (!dataPatient.value.programs.length) {
        if (!program || !programType) {
          router.push('/dashboard')
        }
      }
      if (program === 'aurora') {
        console.log(programType.name, '<<')
        switch (programType.name) {
          case 'FASLODEX':
            pdfRoute.value = '/resources/pdf/AuroraFaslodex.pdf'
            break
          case 'IMFINZI':
            pdfRoute.value = '/resources/pdf/AuroraImfinzi.pdf'
            break
          case 'LYNPARZA':
            pdfRoute.value = '/resources/pdf/AuroraLynparza.pdf'
            break
          case 'TAGRISSO':
            pdfRoute.value = '/resources/pdf/AuroraTagrisso.pdf'
            break

          default:
            break
        }
      } else {
        console.log('programType:', programType.name)
        switch (programType.name) {
          case 'FASLODEX':
            pdfRoute.value = '/resources/pdf/PapFaslodex.pdf'
            break
          case 'IMFINZI':
            pdfRoute.value = '/resources/pdf/PapImfinzi.pdf'
            break
          case 'LYNPARZA':
            pdfRoute.value = '/resources/pdf/PapLynparza.pdf'
            break
          case 'TAGRISSO':
            pdfRoute.value = '/resources/pdf/PapTagrisso.pdf'
            break
          case 'CALQUENCE':
            pdfRoute.value = '/resources/pdf/PapCalquence.pdf'
            break
          case 'ENHERTU':
            pdfRoute.value = '/resources/pdf/PapEnhertu.pdf'
            break
          case 'IMJUDO':
            pdfRoute.value = '/resources/pdf/PapImjudo.pdf'
            break

          default:
            break
        }
      }

      // console.log(userData.user.id, 'iniuserData')
      console.log(res, 'inidokta')
      console.log(programType, 'iniProgramType')
    })
    const showModal = () => {
      visible.value = true
    }
    const handleOk = () => {
      visible.value = false
    }
    const undo = () => {
      if (signaturePad.value) {
        signaturePad.value.undoSignature()
      }
    }
    const save = async () => {
      if (signaturePad.value) {
        const { isEmpty, data } = signaturePad.value.saveSignature()
        if (!isEmpty) {
          await addSignatureToPDF(data)
        }
      }
    }

    const addSignatureToPDF = async signatureDataUrl => {
      // Fetch the existing PDF document
      const existingPdfBytes = await fetch(pdfRoute.value).then(res => res.arrayBuffer())
      console.log(existingPdfBytes, '<<<')
      // Load the PDFDocument
      const pdfDoc = await PDFDocument.load(existingPdfBytes)

      // Embed the signature image
      const pngImage = await pdfDoc.embedPng(signatureDataUrl)

      // Get the first page of the document
      const pages = pdfDoc.getPages()
      const lastPage = pages[pages.length - 1]

      // Set the position and size of the signature
      const { width, height } = lastPage.getSize()
      const pngDims = pngImage.scale(0.2)

      lastPage.drawImage(pngImage, {
        x: 70, // Adjust the X position
        y: 220, // Adjust the Y position
        width: pngDims.width,
        height: pngDims.height,
      })
      const today = new Date()
      const formattedDate = today.toISOString().split('T')[0]
      console.log(formattedDate)
      const name = dataPatient.value.fullname
      const date = formattedDate
      const fontSize = 12

      lastPage.drawText(name, {
        x: 100, // Align with the signature
        y: 255, // Position below the signature
        size: fontSize,
      })

      lastPage.drawText(date, {
        x: 100, // Align with the signature
        y: 240, // Position below the name
        size: fontSize,
      })
      // Serialize the PDFDocument to bytes (a Uint8Array)
      const pdfBytes = await pdfDoc.save()

      // Convert the Uint8Array to a Blob and create an object URL
      const blob = new Blob([pdfBytes], { type: 'application/pdf' })
      const file = new File([blob], 'signed_document.pdf', { type: 'application/pdf' })
      console.log(file)
      const { data } = await uploadConsent('signed_document', file)
      // const response = await uploadConsent('signed_document', file)
      const url = URL.createObjectURL(blob)
      console.log('Upload response:', data.data)
      const category = store.state.program
      const programTypeId = store.state.programType
      const userData = JSON.parse(localStorage.getItem('userData'))
      consent.value = data.data
      isSigned.value = true
      const signedPdfUrl = URL.createObjectURL(blob) // Create an Object URL for the signed PDF
      pdfRoute.value = signedPdfUrl // Update the PDF route to the signed PDF
      visible.value = false
      notification.success({
        message: 'Success Add Signature!',
        description: 'Please check the document',
      })
      // const response = await createProgram(
      //   userData.user.id,
      //   selectedDoctorId.value,
      //   programTypeId,
      //   consent,
      //   category,
      // )
      // if (response) {
      //   router.push(`/program/${route.params.program}/${store.state.programType}`)
      // }
    }
    const consent = ref('')
    const submitDocument = async () => {
      const category = store.state.program
      const programTypeId = store.state.programType
      const userData = JSON.parse(localStorage.getItem('userData'))
      const response = await createProgram(
        userData.user.id,
        selectedDoctorId.value,
        programTypeId,
        consent.value,
        category,
      )
      if (response) {
        router.push(`/mainProgram`)
      }
    }
    const onSearchDocter = value => {
      // Filter daftar kota berdasarkan input pengguna
      filteredDoctors.value = doctors.value.filter(doctor =>
        doctor.fullname.toLowerCase().includes(value.toLowerCase()),
      )
    }

    return {
      onSearchDocter,
      submitDocument,
      pdfRoute,
      doctors,
      filteredDoctors,
      selectedDoctorId,
      isSigned,
      visible,
      showModal,
      handleOk,
      undo,
      save,
      signaturePad,
    }
  },
})
</script>

<style lang="scss" module>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.custom-button-yes {
  width: 100%;
  margin-top: 10px;
  height: 40px !important;
  font-weight: 500;
  font-size: 12px !important;
  text-transform: uppercase;
  background: linear-gradient(to right, #eda702, #c76b1e) !important;
  border: 1px solid #eda702 !important;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #eda702 !important;
    background: transparent !important;
  }
}

.buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
}
</style>
